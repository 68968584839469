import tw from 'twin.macro';
import React, { useState } from 'react';
import Gallery from 'react-grid-gallery';

import { Heading } from '../atoms';
import Video from '../elements/Video';
// import SanityImage from '../SanityImage';

const galleryCategories = ['images', 'videos'];

const GalleryPageTemplate = ({ gallery }) => {
  const [selectedGalleryType, setSelectedGalleryType] = useState('images');
  const isBrowser = typeof window === 'undefined';
  const videos = gallery.videos
    ? gallery.videos.map((item) => ({ id: item, src: item }))
    : [];
  const images = gallery.images
    ? gallery.images.map((item) => ({
        src: item.asset.url,
        thumbnail: item.asset.url,
      }))
    : [];
  return (
    <>
      <Heading type="h1" center>
        {gallery.title}
      </Heading>
      <div tw="my-8 flex justify-center px-4">
        <ul tw="flex cursor-pointer border-b lg:justify-center overflow-auto">
          {galleryCategories.map((item) => (
            <li
              key={item}
              css={[
                tw`rounded-t-lg uppercase`,
                item === selectedGalleryType && tw`bg-tertiary text-white`,
              ]}
            >
              <button
                tw="py-2 px-6"
                type="button"
                onClick={() => setSelectedGalleryType(item)}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {!isBrowser && (
        <React.Suspense fallback={<div />}>
          {selectedGalleryType === 'images' && (
            <div tw="max-w-screen-lg mx-auto grid">
              <Gallery
                images={images}
                margin={6}
                isSelected={false}
                enableImageSelection={false}
                rowHeight={130}
              />
            </div>
          )}
          {selectedGalleryType === 'videos' && (
            <div tw="max-w-screen-lg mx-auto">
              {videos.map((item) => (
                <div key={item.id} tw="mb-14">
                  <Video title={gallery.title} src={item.src} />
                </div>
              ))}
            </div>
          )}
        </React.Suspense>
      )}
    </>
  );
};
export default GalleryPageTemplate;
