import React from 'react';
import 'twin.macro';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Gallery from '../components/gallery/Gallery';
import SanityImage from '../components/SanityImage';
import config from '../utils/config';

export const query = graphql`
  query GalleryPageQuery {
    sanityGallery {
      title
      featuredImage {
        asset {
          gatsbyImageData
        }
      }
      images {
        _key
        asset {
          url
        }
      }
      videos
    }
  }
`;

const GalleryPage = ({ data }) => {
  const gallery = data.sanityGallery;
  return (
    <Layout>
      <Seo
        title={gallery.title}
        description={`Welcome to ${config.siteName}`}
      />
      <div tw="mx-auto max-w-screen-2xl">
        {gallery.featuredImage && (
          <SanityImage fluid={gallery.featuredImage.asset.gatsbyImageData} />
        )}
      </div>
      <div tw="container mx-auto p-4 my-14">
        <Gallery gallery={gallery} />
      </div>
    </Layout>
  );
};
export default GalleryPage;
