import 'twin.macro';
import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  padding-top: 56.25%;
`;

const Video = ({ title, src }) => (
  <VideoWrapper tw="relative w-full overflow-hidden md:my-0 my-8">
    <iframe
      // autoPlay={false}
      title={title}
      tw="absolute h-full w-full top-0 left-0 right-0 bottom-0"
      src={src}
    />
  </VideoWrapper>
);
export default Video;
